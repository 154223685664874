export const getPostsFromResponse = (response, fieldname, noReverse) => {
  if (
    !response ||
    !response.data ||
    !response.data.data ||
    !response.data.data[fieldname]
  ) {
    return false;
  } else {
    if (noReverse) return response.data.data[fieldname];
    else return response.data.data[fieldname].reverse();
  }
};

export const detectPostTypes = (postList) => {
  postList.forEach((post) => {
    if (post.blocks && post.blocks.length) {
      post._postType = post.blocks[0].type;
    }
  });

  return postList;
};
