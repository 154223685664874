import { apolloClient } from '@/services/apollo.service';
import { EventBus } from '@/services/event-bus';
import gql from 'graphql-tag';

export const WebSocket = function () {
  const POSTS_SUBSCRIPTION = gql`
    subscription onNewChatMessage($channelId: String!) {
      onNewChatMessage(channelId: $channelId) {
        channelId
        post {
          id
          createdAt
          editedAt
          deletedAt
          author {
            id
            fullName
            avatar
          }
          blocks {
            index
            data
            original: data
            type
          }
          wasSeen
          read
          channelId
        }
      }
    }
  `;

  const REACTIONS_SUBSCRIPTION = gql`
    subscription onNewChatReaction($channelId: String!) {
      onNewChatReaction(channelId: $channelId) {
        channelId
        postReaction {
          postId
          reactions {
            reaction
            count
            reacted
          }
        }
      }
    }
  `;

  let subscriptions = [];
  let subscribedChannels = [];

  let subscribedReactions = [];
  let reactionSubscription = null;

  return {
    subscribe(channelId) {
      if (!channelId) return;
      if (subscribedChannels.indexOf(channelId) > -1) {
        return;
      } else {
        subscribedChannels.push(channelId);
      }

      // console.log("Subscribing to", channelId)

      let sub = apolloClient.subscribe({
        query: POSTS_SUBSCRIPTION,
        variables: { channelId: channelId },
        errorPolicy: 'all',
      });

      subscriptions.push(
        sub.subscribe({
          errorPolicy: 'all',
          next(data) {
            if (data.data.onNewChatMessage) {
              let newMessage = data.data.onNewChatMessage;
              EventBus.$emit('onNewMessage', newMessage);
            }
          },
          error(err) {
            console.log('Subscription error', err);
          },
        })
      );
    },
    unsubscribeAll() {
      // console.log("Unsubscribing all");
      subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
      subscribedChannels = [];
    },

    subscribeReactions(channelId) {
      if (!channelId) return;
      if (subscribedReactions.indexOf(channelId) > -1) {
        return;
      } else {
        subscribedReactions.push(channelId);
      }

      let sub = apolloClient.subscribe({
        query: REACTIONS_SUBSCRIPTION,
        variables: { channelId: channelId },
        errorPolicy: 'all',
      });
      console.log('subscribed reactions to ', channelId);
      reactionSubscription = sub.subscribe({
        errorPolicy: 'all',
        next(data) {
          if (data.data.onNewChatReaction) {
            let newMessage = data.data.onNewChatReaction;
            EventBus.$emit('onNewReaction', newMessage);
          }
        },
        error(err) {
          console.log('Reactions subscription error', err);
        },
      });
    },

    unsubscribeReactions() {
      console.log('unsubscribed reactions');
      if (reactionSubscription) reactionSubscription.unsubscribe();
      subscribedReactions = [];
      reactionSubscription = null;
    },
  };
};
