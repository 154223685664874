//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlockEditor from '@/components/Together/BlockEditor.vue';
import { Replies } from '@/services/api.service';
import { EventBus } from '@/services/event-bus';

export default {
  name: 'PostCreate',
  props: ['channel', 'prefill', 'color', 'edit'],
  data() {
    return {
      loggedUser: null,
      error: {
        postCreate: false,
      },
      blocks: null,
      isEditingPost: null,
    };
  },
  methods: {
    setBlocks(blocks) {
      this.blocks = blocks;
      this.$emit('blockchanged', blocks);
    },

    postReply() {
      let options = {
        channelId: this.channel,
        blocks: this.blocks.map((i) => {
          return {
            data: i.data,
            type: i.type,
          };
        }),
      };

      let action = Replies.add;
      if (this.edit) {
        options.postId = this.edit.id;
        action = Replies.edit;
      }

      action(options).then(
        (response) => {
          if (response && response.data && response.data.data) {
            this.$emit('postsubmit', {
              response: response.data.data,
              reload: this.isEditingPost ? true : false,
            });
            this.finishEditing();
            this.$refs.form.reset();
            this.error.postCreate = false;
          } else {
            this.error.postCreate = true;
          }
        },
        () => {
          this.error.postCreate = true;
        }
      );
    },

    finishEditing() {
      this.isEditingPost = null;
      EventBus.$emit('BlockEditor.resetBlocks');
      this.$emit('finishEditing');
    },
  },
  watch: {
    edit(newval) {
      if (newval) this.isEditingPost = JSON.parse(JSON.stringify(newval));
      else this.isEditingPost = null;
    },
  },
  mounted() {
    this.isEditingPost = null;
    this.loggedUser = this.$store.state.profile;
  },
  computed: {},
  created() {},
  components: {
    BlockEditor,
  },
};
