//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Files } from '@/services/api.service';
import { EventBus } from '@/services/event-bus';

const HeadingBlock = {
  type: 'HEADING',
  data: [],
  params: { name: 'Title', maxChars: 100 },
};

const ParagraphBlock = {
  type: 'PARAGRAPH',
  data: [],
  params: { name: 'Text', maxChars: 1000 },
};

const LinkBlock = {
  type: 'LINK',
  data: [],
  params: { name: 'Link' },
};

const GalleryBlock = {
  type: 'GALLERY',
  data: [],
  params: { name: 'Image' },
};

const Blocks = {
  HEADING: HeadingBlock,
  PARAGRAPH: ParagraphBlock,
  LINK: LinkBlock,
  GALLERY: GalleryBlock,
};

export default {
  name: 'BlockEditor',
  data() {
    return {
      inputHasFocus: false,
      blocks: null,
      uploadUrl: null,
      lastBlockId: 0,
      activeUploadBlock: null,
      error: {
        upload: false,
      },
      inProgress: true,
    };
  },
  props: ['type', 'prefill', 'color'],
  components: {},
  methods: {
    copyBlock(definition) {
      return JSON.parse(JSON.stringify(definition));
    },
    setBlocks() {
      if (this.type == 'reply') {
        this.blocks = [{ id: 0, ...this.copyBlock(ParagraphBlock) }];
        this.lastBlockId = 1;
      }
      if (this.type == 'post') {
        this.blocks = [{ id: 0, ...this.copyBlock(ParagraphBlock) }];
        this.lastBlockId = 1;
      }
    },
    addBlock(blockType) {
      if (!blockType || !Blocks[blockType]) return;
      this.blocks.push({
        id: this.lastBlockId,
        ...this.copyBlock(Blocks[blockType]),
      });
      this.lastBlockId++;
    },
    onFileChanged(e) {
      let file = e.target.files[0];
      // 1: upload file using mutation
      // 2: get result url
      // 3: create new block with this url
      // 4: submit form
      Files.upload(file).then(
        (res) => {
          const fileUrl = res.fileUrl;
          this.blocks = [
            { id: 0, type: 'FILE', data: [fileUrl, file.size, file.name] },
          ];
          this.$emit('blockchanged', this.blocks);
          // console.log(this.blocks)
          this.submit();
        },
        (err) => {
          console.error('Upload failed:', err);
        }
      );
    },
    onImageChanged(e) {
      let file = e.target.files[0];
      // 1: upload file using mutation
      // 2: get result url
      // 3: create new block with this url
      // 4: submit form
      Files.upload(file).then(
        (res) => {
          const fileUrl = res.fileUrl;
          this.blocks = [{ id: 0, type: 'GALLERY', data: [fileUrl] }];
          this.$emit('blockchanged', this.blocks);
          // console.log(this.blocks)
          this.submit();
        },
        (err) => {
          console.error('Upload failed:', err);
        }
      );
    },
    uploadStarted(block) {
      //console.log(params);
      this.activeUploadBlock = block;
    },
    submit(e) {
      if (this.inProgress) return;

      if (e && e.code === 'Enter' && e.shiftKey !== true) {
        this.inProgress = true;
        this.$emit('submit');
        setTimeout(() => (this.inProgress = false), 1000);
      } else if (!e) {
        this.inProgress = true;
        this.$emit('submit');
        setTimeout(() => (this.inProgress = false), 1000);
      }
    },
    blockchanged() {
      this.$emit('blockchanged', this.blocks);
    },
  },
  watch: {
    blocks() {
      this.$emit('blockchanged', this.blocks);
    },
    prefill(val) {
      if (val) {
        this.blocks = this.prefill.map((i) => {
          return { data: i.original ? i.original : i.data, type: i.type };
        });
      } else {
        this.setBlocks();
      }
    },
  },
  computed: {},
  mounted() {
    EventBus.$on('BlockEditor.resetBlocks', this.setBlocks);
    if (this.prefill) {
      this.blocks = JSON.parse(JSON.stringify(this.prefill));
    } else {
      this.setBlocks();
    }
    this.inProgress = false;
  },
  destroyed() {
    EventBus.$off('BlockEditor.resetBlocks');
  },
};
